import styles from './UpdateTag.module.css'
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';




const UpdateTag = (props) => {
    const [publisherId, setPublisherId] = useState('');
    const [tagName, setTagName] = useState('');
    const [bannerWidth, setBannerWidth] = useState('');
    const [bannerHeight, setBannerHeight] = useState('');
    const [speed, setSpeed] = useState('');
    const [photo, setPhoto] = useState('');
    const [link, setLink] = useState('');
    const [platformName, setPlatformName] = useState('');
    const [platforms, setPlatforms] = useState(props.platforms);
    const [domain, setDomain] = useState('');
    const domains = useRef(
        [
            {id:1,name:'plyair.com'},
            {id:2,name:'companionaudio.com'},
            {id:3,name:'myaudioads.com'},
            {id:4,name:'yo-audioplayer.com'}
        ]
    )
    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/tag/${props.id}`, {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                setPublisherId(data.publisher_id);
                setTagName(data.description);
                setBannerHeight(data.height)
                setBannerWidth(data.width);
                setSpeed(data.speed)
                setLink(data.link)
                setPhoto(data.photo)
                setPlatformName(data.name)
                setDomain(data.domain)

            } catch (err) {
                console.error(err);
            }
        }
        )()


    }, [])

    const save = async () => {
        debugger
       
        const tagUpdated = {
            id: props.id,
            name: platformName,
            publisherId: publisherId,
            description: tagName,
            width: bannerWidth,
            height: bannerHeight,
            debug: 'false',
            speed,
            photo,
            link,
            domain
        }

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/update/tag`, {
                method: 'Put',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(tagUpdated)
            });
            if (res.status === 403) {
                navigate("/");
            }

            const data = await res.json();
            props.onClose();



        } catch (err) {
            console.log(err);
        }

    }

    const backToLayOut = () => {
        props.onClose();
    }

    const handleChange = (e) => {
        setPlatformName(e.target.value);
    }

    const handleDomain = (e) => {

        setDomain(e.target.value);
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={true}
        >
            <Card sx={{ maxWidth: 500, maxHeight: 750, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <CardHeader
                    action={
                        <IconButton sx={{ position: 'relative', left: '180%' }} onClick={backToLayOut}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title="Edit Tag"
                    sx={{ color: '#289FD2' }}
                />
                <CardContent sx={{flexGrow: 1, overflow: 'auto', width: "inherit" }} >
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },

                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Publishers</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={platformName}
                                label="Publishers"
                                onChange={handleChange}
                            >
                                {platforms.map((platform) => (
                                    <MenuItem key={platform.id} value={platform.name}>
                                        {platform.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={domain}
                                label="Domains"
                                onChange={handleDomain}
                            >
                                {domains.current.map((domain) => {
                                    return (<MenuItem key={domain.id} value={domain.name}>{domain.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                        <TextField type='number' value={publisherId} name='publisherId' onChange={(e) => { setPublisherId(e.target.value) }} id="outlined-basic" label="Adevrtiser ID" variant="outlined" />
                        <TextField type='text' value={tagName} name='description' onChange={(e) => { setTagName(e.target.value) }} id="outlined-basic" label="Tag Name" variant="outlined" />
                        <TextField type='text' value={bannerWidth} name='bannerWidth' onChange={(e) => { setBannerWidth(e.target.value) }} id="outlined-basic" label="Banner Width" variant="outlined" />
                        <TextField type='text' value={bannerHeight} name='bannerHeight' onChange={(e) => { setBannerHeight(e.target.value) }} id="outlined-basic" label="Banner Height" variant="outlined" />
                        <TextField type='text' value={speed} name='speed' onChange={(e) => { setSpeed(e.target.value) }} id="outlined-basic" label="Speed" variant="outlined" />
                        <TextField type='text' value={photo} name='photo' onChange={(e) => { setPhoto(e.target.value) }} id="outlined-basic" label="photo" variant="outlined" />
                        <TextField type='text' value={link} name='link' onChange={(e) => { setLink(e.target.value) }} id="outlined-basic" label="link" variant="outlined" />
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                    <button onClick={save} className={styles.submitButton} type="submit">save</button>
                </CardActions>
            </Card>
        </Backdrop>
    )

}

export default UpdateTag