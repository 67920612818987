import { useNavigate, Outlet } from 'react-router-dom'
import styles from './Layout.module.css'
import React, { useEffect, useState } from "react";
import avatar1 from '../../assets/avatar1.png'
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddchartIcon from '@mui/icons-material/Addchart';
import StyleIcon from '@mui/icons-material/Style';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import TagCreator from '../TagCreator/TagCreator';
import NewPublisher from '../NewPublisher/NewPublisher';
import NewDemand from '../NewDemand/NewDemand';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';


const Layout = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const [user, setuser] = useState(JSON.parse(localStorage.getItem('name')) + ' ' + JSON.parse(localStorage.getItem('lastName')));
    const [Useremail, setUserEmail] = useState(JSON.parse(localStorage.getItem('email')));
    const [open, setOpen] = useState({
        tagOpen: false,
        publisherOpen: false,
        advertiserOpen: false,
    })
    const drawerWidth = 260;

    const CustomAppBar = styled(AppBar)(({ theme }) => ({
        backgroundColor: theme.status.danger
    }));

    const theme = createTheme({
        status: {
            danger: 'white',
        },
    });

    useEffect(() => {
        document.title = process.env.REACT_APP_API_HEAD_NAME;
    }, []);


    const publishers = () => {
        navigate('/admin/layout/publishers');

    }
    const tags = () => {
        navigate('/admin/layout/tags');
    }

    const advertisers = () => {
        navigate('/admin/layout/demand');

    }

    const peakDuplicator = () => {
        navigate('/admin/layout/duplicator');
    }

    const peakDuplicatorEvernetix = () => {
        navigate('/admin/layout/duplicator/evernetix');
    }

    const peakDuplicatorThree = () => {
        navigate('/admin/layout/duplicator/three');
    }

    const getReport = () => {
        navigate('/admin/layout/report');
    }

    const Logout = () => {
        localStorage.removeItem('token');
        navigate('/');
    }

    const handleClose = () => {
        setOpen({
            tagOpen: false,
            publisherOpen: false,
            advertiserOpen: false,
        });
        
        if (open.tagOpen) {
            navigate('/admin/layout/tags');
        } else if (open.publisherOpen) {
            navigate('/admin/layout/publishers');
        } else if (open.advertiserOpen) {
            navigate('/admin/layout/demand');
        }
    };

    const createTag = () => {
        setOpen({ tagOpen: true, publisherOpen: false, advertiserOpen: false });
    }

    const addPublisher = () => {
        setOpen({ tagOpen: false, publisherOpen: true, advertiserOpen: false });
    }

    const addDemand = () => {
        setOpen({ tagOpen: false, publisherOpen: false, advertiserOpen: true });
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Box style={{ display: 'flex' }}>
                    <CssBaseline />


                    {/* app bar */}
                    <CustomAppBar
                        position="fixed"
                        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, display: 'flex' }}
                    >
                        <Box sx={{ flexGrow: 1 }} />  {/* This pushes the buttons to the right */}
                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0px' }}>
                                <Typography variant="h6" noWrap component="div">
                                    {location.pathname === '/admin/layout/tags' && (
                                        <button onClick={createTag} className={styles.addTag}>New Tag</button>
                                    )}
                                    {location.pathname === '/admin/layout/publishers' && (
                                        <button onClick={addPublisher} className={styles.addPublisher}>New Publisher</button>
                                    )}
                                    {location.pathname === '/admin/layout/demand' && (
                                        <button onClick={addDemand} className={styles.addDemand}>New Demand</button>
                                    )}
                                </Typography>
                            </Toolbar>

                            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0px' }}>
                                <Typography variant="h6" noWrap component="div">
                                    <button className={styles.logoutButton} onClick={Logout}>Logout</button>
                                </Typography>
                            </Toolbar>
                        </div>
                    </CustomAppBar>

                    {/* drawer */}
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <Toolbar />
                        <Avatar alt={process.env.REACT_APP_API_NAME} src={process.env.REACT_APP_API_AVATAR} sx={{ position: 'relative', left: '40%', bottom: '50px' }} />
                        <List sx={{ flex: 1 }}>
                            <ListItem disablePadding onClick={publishers}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ScatterPlotIcon />
                                    </ListItemIcon>
                                    <ListItemText>Publishers</ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={tags}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <StyleIcon />
                                    </ListItemIcon>
                                    <ListItemText>Tags</ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={advertisers}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WhatshotIcon />
                                    </ListItemIcon>
                                    <ListItemText >Advertisers</ListItemText>
                                </ListItemButton>
                            </ListItem>
                            {process.env.REACT_APP_API_DUPLICATOR === 'true' ?
                                <div>
                                    <ListItem disablePadding >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ViewInArIcon />
                                            </ListItemIcon>
                                            <ListItemText onClick={peakDuplicator} >Duplicator</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ViewInArIcon />
                                            </ListItemIcon>
                                            <ListItemText onClick={peakDuplicatorEvernetix} >Duplicator Evernetix</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <ViewInArIcon />
                                            </ListItemIcon>
                                            <ListItemText onClick={peakDuplicatorThree} >Duplicator Peak-3</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <AddchartIcon />
                                            </ListItemIcon>
                                            <ListItemText onClick={getReport} >Performance Overview</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </div> : null
                            }
                        </List>
                        <CardHeader
                            avatar={
                                <Avatar alt={user} src={avatar1} ></Avatar>
                            }
                            title={user}
                            subheader={Useremail}
                        />
                    </Drawer>

                    {/* main */}
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, overflowY: 'scroll', height: '100vh' }}
                    >
                        <Toolbar />
                        <Outlet></Outlet>
                    </Box>
                </Box>
                <Dialog
                    open={open.tagOpen || open.publisherOpen || open.advertiserOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
                >
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    <DialogContent>
                        {open.tagOpen && <TagCreator onClose={handleClose} />}
                        {open.publisherOpen && <NewPublisher onClose={handleClose} />}
                        {open.advertiserOpen && <NewDemand onClose={handleClose} />}
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
        </div>
    )

}

export default Layout