import { useState, useEffect } from "react";
import styles from './Demand.module.css'
import DemandVasts from "../DemandVasts/DemandVasts";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useLocation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";


const Demand = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [demand, setDemand] = useState([]);
    const [id, setId] = useState([]);
    const [demandVast, setDemandVast] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [capacityFilter, setCapacityFilter] = useState(false);
    const [dailyCapacityFilter, setDailyCapacityFilter] = useState(false);

    useEffect(() => {
        try {
            (async () => {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/demand`, {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                if (data) {
                    setLoading(false);
                    setDemand(data.publishers);
                    setCapacityFilter(data.capacityFilter);
                    setDailyCapacityFilter(data.dailyCapacityFilter);
                }
            }
            )()
        } catch (error) {
            console.error(error);
        }
    }, [open, location])

    const viewVasts = (e) => {
        setId(e.target.id);
        setDemandVast(true);
        setOpen(true);
    }

    const handleClose = () => {
        setDemandVast(false);
        setOpen(false);
    };

    const updateQuota = async (id) => {
        try {
            const { quota, daily_capacity } = demand.find(d => d.id === id);
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/demand/${id}`, {
                mode: 'cors',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ quota, daily_capacity })
            });

            const data = await res.json();
            setDemand(demand.map(d => d.id === data.id ? { ...d, data } : d));
        } catch (error) {
            console.error(error);
        }
    }

    const onKeyPress = (e, id, key) => {
        if (e.charCode === 13) {
            return updateQuota(id, { [key]: e.target.value });
        }
    }

    const updateDemand = (id, value) => {
        setDemand(demand.map(d => {
            return d.id === id ? Object.assign(d, value) : d;
        }))
    }

    return (
        <div className={styles.wrapper}>
            {loading ? <LinearProgress /> :
                <table className={styles.table}>
                    <thead className={styles.tableRowHeader}>
                        <tr>
                            <th className={styles.tableRowHeader1}>ID</th>
                            <th>Name</th>
                            {capacityFilter && <th>Traffic Running</th>}
                            {capacityFilter && <th>Hourly Quota</th>}
                            {dailyCapacityFilter && <th className="capacity-column">Daily Quota</th>}
                            <th className={styles.tableRowHeader2}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {demand.map((demand) => {
                            return (<tr key={demand.id}>
                                <td>{demand.id}</td>
                                <td>{demand.name}</td>
                                {capacityFilter && <td>{demand.blocked || demand.daily_blocked ? 'no' : 'yes'}</td>}
                                {capacityFilter && <td>
                                    <TextField type='number' onBlur={(e) => updateQuota(demand.id)}
                                        id={`${demand.id}-quota-update`}
                                        label="Quota"
                                        variant="outlined"
                                        value={demand.quota}
                                        onChange={e => updateDemand(demand.id, { quota: e.target.value })}
                                        onKeyPress={e => onKeyPress(e, demand.id, 'quota')}
                                    />
                                </td>}
                                {dailyCapacityFilter &&
                                    <td>
                                        <TextField type='number' onBlur={(e) => updateQuota(demand.id)}
                                            id={`${demand.id}-daily-quota-update`}
                                            label="Daily Quota"
                                            variant="outlined"
                                            value={demand.daily_capacity}
                                            onChange={e => updateDemand(demand.id, { daily_capacity: e.target.value })}
                                            onKeyPress={e => onKeyPress(e, demand.id, 'daily_capacity')}
                                        />
                                    </td>
                                }
                                <td>
                                    <button id={demand.id} onClick={viewVasts} className={styles.addVast}>view Vast
                                    </button>
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    {demandVast &&
                        <DemandVasts
                            id={id}
                            onClose={handleClose}
                        />
                    }
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default Demand