import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from './Publishers.module.css'
import LinearProgress from '@mui/material/LinearProgress';

const Publishers = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [publishers, setPublishers] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/publishers/`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setLoading(false);
                setPublishers(data);
            }

        }
        )()

    }, [location])

    return (
        <div className={styles.wrapper} >
            <div className={styles.tableWrapper}>
                {loading ? <LinearProgress /> :
                    <table className={styles.table}>
                        <thead className={styles.tableRowHeader}>
                            <tr>
                                <th className={styles.tableRowHeader1}>ID</th>
                                <th>Name</th>
                                <th>Created At</th>
                                <th className={styles.tableRowHeader2}>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {publishers.map((publisher) => {
                                return (<tr key={publisher.id}>
                                    <td>{publisher.id}</td>
                                    <td>{publisher.name}</td>
                                    <td>{publisher.createdAt}</td>
                                    <td>{publisher.updatedAt}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )

}

export default Publishers